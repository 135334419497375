<template >
	<v-card v-if="!loading" :height="height" ref="content" style="border-radius: 12px;overflow: auto">
				<v-app-bar flat>
					<v-toolbar-title class="fixed-title">{{$t('vitals')}} test</v-toolbar-title>
					<v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-select
							value="LAST7"
							:items="filterCases"
							item-text="text"
							item-value="value"
							filled
							rounded
							dense
							hide-details
							style="border-radius: 8px; width: 100px; height: 38px; font-size: 14px;"
							@change="filterData">
						</v-select>
<!--						<v-btn icon color="primary" @click="showPDFFunction">-->
<!--						<v-icon class="mx-2" color="primary" style="cursor: pointer; height: 16px; width: 16px; ">mdi-eye</v-icon>-->
<!--						</v-btn>-->
<!--				<v-btn icon color="primary" @click="medicalReports">-->
<!--					<v-icon medium>mdi-cloud-download-outline</v-icon>-->
<!--				</v-btn>-->
<!--						<v-btn icon color="primary" @click="toggleFullScreen">-->
<!--							<v-icon large>{{ fullScreenIcon }}</v-icon>-->
<!--						</v-btn>-->
				</v-app-bar>
    <div class="card-content-container">
			<v-card style="overflow-y: auto" flat >
		<div v-if=" assignedMedicalDevices !== undefined && assignedMedicalDevices.length === 0" class="pa-0 " style="backgroundcolor: transparent; border: 1px #E8E8E8; border-radius: 5px; line-height: 800px">
			<span class="card_color--text pa-2" depressed outlined style=" border: solid #E8E8E8; background-color: transparent !important; border-radius: 8px;">{{ $t("thisUserHasNoDevices") }}</span>
		</div>
				<RenderDeviceData v-for="(item, i) in assignedMedicalDevices" :key="i" class="fill-height" :item="item" :idealWeight="idealWeight" :dates="dates" @show="showItem(item)" @hide="hideItem(item)"/>
			</v-card>
				<div v-if="assignedMedicalDevices === undefined" class="pa-6" style=" background-color: transparent; border: 1px dashed #E8E8E8; border-radius: 5px;">
					<span class="card_color--text pa-2" depressed outlined style="border: solid #E8E8E8; background-color: transparent !important; border-radius: 8px;">{{ $t("thisUserHasNoHub") }}</span>
				</div>
    </div>
<!--		<show-notes-dialog v-if="showPDF" :dialog="showPDF" :vitalsPDF="exportPDF" :detailedUser="detailedUser" @close="showPDFFunction"></show-notes-dialog>-->
<!--   <medicalReportsPDF v-if="medicalPDF" :detailedUser="detailedUser" :assignedMedicalDevices="assignedMedicalDevices" :dialog="medicalPDF" @close="medicalReports"></medicalReportsPDF>-->
	</v-card>
</template>
<script>
/* eslint-disable no-unused-vars */
import autoTable from 'jspdf-autotable';
import { formatterFilter } from '@/utils/luxon-formater';
import { vitalCheckLogo } from '@/assets/';
import { mapState, mapGetters } from 'vuex';
import RenderDeviceData from './RenderDeviceData.vue';
import jsPDF from 'jspdf';
// import medicalReportsPDF from '@/views/dialogs/MedicalReportsPDF';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
// import ShowNotesDialog from '@/views/dialogs/ShowNotesDialog';
export default {
	props: ['dialog'],
	components: {
		RenderDeviceData,
		// medicalReportsPDF,
		// ShowNotesDialog,
	},
	data () {
		return {
			logo: vitalCheckLogo,
			dateData: [],
			keys: [],
			allDates: [],
			todos: [
				{ title: 'title 1', description: 'description 1' },
				{ title: 'title 2', description: 'description 2' },
				{ title: 'title 3', description: 'description 3' },
				{ title: 'title 4', description: 'description 4' },
			],
			dates: ['2021-09-01', '2022-01-01'],
			selectedItem: 1,
			groups: [],
			allData: [],
			loading: true,
			filterCases: [
				{ text: this.$t('today'), value: 'TODAY' },
				{ text: this.$t('last-7-days'), value: 'LAST7' },
				{ text: this.$t('last-14-days'), value: 'LAST14' },
				{ text: this.$t('last-30-days'), value: 'LAST30' },
				{ text: this.$t('all'), value: 'ALL' },
			],
			hide: {
				temp: false,
				oxi: false,
				blood: false,
				glu: false,
				chol: false,
				scale: false,
			},
			items: [{ hidden: true }, { hidden: true }],
			showPDF: false,
			medicalPDF: false,
			oximeterTooltip: this.$t('oximeterIconTooltip'),
			cholesterolTooltip: this.$t('cholesterolMeterIconTooltip'),
			thermometerTooltip: this.$t('thermometerIconTooltip'),
			scaleTooltip: this.$t('scaleIconTooltip'),
			gluclosemeterTooltip: this.$t('glucoseMeterIconTooltip'),
			bloodsugarmeterTooltip: this.$t('bloodSugarMeterIconTooltip'),
			type: null,
			change: false,
			timeLocalization: this.$i18n.locale,
		};
	},
	computed: {
		...mapState({
			bloodPresure: (state) => state.medicaldevice.bloodPresure,
			hubs: (state) => state.nosessionhubs.hubs,
			detailedUser: (state) => state.users.detailedUser,
		}),
		...mapGetters({ roleName: 'authentication/getRole', userSettings: 'authentication/getUserSettings' }),
		fullScreenIcon () {
			return this.type === 'vitals' ? 'mdi-fullscreen-exit' : 'mdi-fullscreen';
		},
		cardClass () {
			if (this.change) {
				return 'elevation-0 mt-n10';
			}
			return 'elevation-0 mt-n3';
		},
		height () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs': return (window.innerHeight - 300).toString() + 'px';
			case 'sm': return (window.innerHeight - 330).toString() + 'px';
			case 'md': return (window.innerHeight - 330).toString() + 'px';
			case 'lg': return (window.innerHeight - 340).toString() + 'px';
			case 'xl': return (window.innerHeight - 330).toString() + 'px';
			default: return '600px';
			}
		},
		dateRangeText () {
			return this.dates[0] + ' - ' + this.dates[1];
		},
		idealWeight () {
			var weight = 0;
			if (this.detailedUser.userData.gender === 'M') {
				weight =
			50 +
			0.9 * (this.detailedUser.userData.patientMetadata.heightInCm - 152);
			} else {
				weight =
			45.5 +
			0.9 * (this.detailedUser.userData.patientMetadata.heightInCm - 152);
			}
			return weight;
		},
		identifier () {
			return this.hubs.hubIdentifier;
		},
		assignedMedicalDevices () {
			return this.hubs.assignedMedicalDevicesList?.map((item) => ({
				deviceDefaultValuesJson: item.deviceDefaultValuesJson,
				deviceType: item.deviceType,
				measurementsData: item.measurementsData,
				deviceName: item.deviceName,
				deviceNameEsp: item.deviceNameEsp,
				hide: this.getDeviceStatus(item.deviceType),
			}));
		},
		deviceName () {
			return this.bloodPresure.deviceName;
		},
	},
	methods: {
		// medicalReports () {
		// 	this.medicalPDF = !this.medicalPDF;
		// },
		// showPDFFunction () {
		// 	this.showPDF = !this.showPDF;
		// },
		phone (val) {
			if (val !== undefined) {
				if (!isValidPhoneNumber(val)) {
					this.phoneErrors = true;
				} else {
					this.phoneErrors = false;
				}
				return new AsYouType().input(val);
			}
		},
		async filterData (item) {
			this.$emit('filter-changed', item);
			const data = {
				id: this.detailedUser.userData.id,
				filter: item,
			};
			await this.getHubsData(data);
		},
		handleDateChange () {
			if (this.dates[0] === undefined || this.dates[1] === undefined) {
				return;
			}
			const startDate = new Date(this.dates[0]);
			const endDate = new Date(this.dates[1]);
			if (startDate < endDate) {
				return;
			}
			this.dates = [
				endDate.toISOString().slice(0, 10),
				startDate.toISOString().slice(0, 10),
			];
		},
		calculateAge (dateString) {
			var today = new Date();
			var birthDate = new Date(dateString.split('T')[0]);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
		hideItem (item) {
			switch (item.deviceType) {
			case 1:
				this.hide.temp = true;
				break;
			case 2:
				this.hide.oxi = true;
				break;
			case 3:
				this.hide.blood = true;
				break;
			case 5:
				this.hide.scale = true;
				break;
			case 6:
				this.hide.glu = true;
				break;
			case 7:
				this.hide.chol = true;
				break;
			}
		},
		showItem (item) {
			switch (item.deviceType) {
			case 1:
				this.hide.temp = false;
				break;
			case 2:
				this.hide.oxi = false;
				break;
			case 3:
				this.hide.blood = false;
				break;
			case 5:
				this.hide.scale = false;
				break;
			case 6:
				this.hide.glu = false;
				break;
			case 7:
				this.hide.chol = false;
				break;
			}
		},
		getDeviceStatus (i) {
			var t = null;
			switch (i) {
			case 1:
				t = this.hide.temp;
				break;
			case 2:
				t = this.hide.oxi;
				break;
			case 3:
				t = this.hide.blood;
				break;
			case 5:
				t = this.hide.scale;
				break;
			case 6:
				t = this.hide.glu;
				break;
			case 7:
				t = this.hide.chol;
				break;
			}
			return t;
		},
		async getUserById (data) {
			await this.$store.dispatch('users/getUserById', data);
			this.loading = false;
		},
		async getHubsData (data) {
			await this.$store.dispatch('nosessionhubs/getHubsData', data);
			this.loading = false;
		},
		// toggleFullScreen () {
		// 	this.change = !this.change;
		// 	if (this.change) {
		// 		this.type = 'vitals';
		// 	} else {
		// 		this.type = null;
		// 	}
		// 	this.$emit('fullscreen', this.type);
		// },
		exportPDF () {
			var isFirstMasurementExported = false;
			// eslint-disable-next-line new-cap
			var doc = new jsPDF('p', 'pt');
			var currentHeight = 15;
			var imgData = new Image();
			imgData.src = this.logo;
			var colorGray = '#808080';
			var black = '#000000';
			var pdfConfig = {
				headerTextSize: 14,
				labelTextSize: 12,
				fieldTextSize: 10,
				lineHeight: 6,
				subLineHeight: 12,
			};
			// var elementHTML =
			var docWidth = doc.internal.pageSize.width;
			var docOffsetHeight = 200;
			var docOffsetBlankPage = 10;
			doc.setFontSize(14);
			currentHeight += pdfConfig.subLineHeight;
			doc.text(41, currentHeight, this.detailedUser.userData?.firstName + this.detailedUser.userData?.lastName);
			// currentHeight += pdfConfig.subLineHeight;
			doc.text(docWidth - 41, currentHeight, this.$t('doctor'), 'right');
			currentHeight += pdfConfig.subLineHeight;
			doc.setTextColor(black);
			doc.setTextColor(colorGray);
			doc.setFontSize(pdfConfig.fieldTextSize);
			doc.text(41, currentHeight, this.detailedUser.userData?.address);
			doc.text(docWidth - 41, currentHeight, this.detailedUser.medicalTeamData[0]?.primaryDoctorFullName, 'right');
			currentHeight += pdfConfig.subLineHeight;
			doc.setTextColor(colorGray);
			doc.setFontSize(pdfConfig.fieldTextSize);
			doc.text(41, currentHeight, this.detailedUser.medicalTeamData[0]?.primaryDoctorFullName);
			doc.text(docWidth - 41, currentHeight, this.detailedUser.medicalTeamData[0]?.hospitalName, 'right');
			doc.setFontSize(pdfConfig.fieldTextSize);
			currentHeight += pdfConfig.subLineHeight;
			doc.text(41, currentHeight, this.phone(this.detailedUser.userData?.phone));
			currentHeight += pdfConfig.subLineHeight;
			doc.text(41, currentHeight, this.detailedUser.userData?.email);

			currentHeight += pdfConfig.subLineHeight;
			doc.text(41, currentHeight, this.detailedUser?.userData?.patientMetadata?.medicalRegistrationNumber ? this.detailedUser?.userData?.patientMetadata?.medicalRegistrationNumber : '');
			// doc.text(docWidth - 41, currentHeight, 'Invoice date', 'right');

			// doc.line(41, currentHeight, docWidth - 41, currentHeight);
			currentHeight += pdfConfig.subLineHeight;
			currentHeight += pdfConfig.subLineHeight;
			const temperatureBody = [];
			const oximeterBody = [];
			const bpmBody = [];
			const scaleBody = [];
			const glucoseBody = [];
			const cholesterolBody = [];
			const temperatureDate = [];
			const oximeterDate = [];
			const bpmDate = [];
			const scaleDate = [];
			const glucoseDate = [];
			const cholesterolDate = [];
			this.assignedMedicalDevices.filter(f => {
				if (f.measurementsData.length) {
					const measurements = f.measurementsData;
					measurements.forEach(element => {
						if (JSON.parse(element.measuredDataJson).Temperature_C !== undefined && JSON.parse(element.measuredDataJson).Temperature_F !== undefined) {
							temperatureBody.push([formatterFilter(element.measuredAt, 'datetime_med', this.timeLocalization), JSON.parse(element.measuredDataJson).Temperature_C, JSON.parse(element.measuredDataJson).Temperature_F]);
							temperatureDate.push([formatterFilter(element.measuredAt, 'date_med', this.timeLocalization)]);
						}
						if (JSON.parse(element.measuredDataJson).Spo2 !== undefined && JSON.parse(element.measuredDataJson).BPM !== undefined) {
							oximeterBody.push([formatterFilter(element.measuredAt, 'datetime_med', this.timeLocalization), (JSON.parse(element.measuredDataJson).Spo2).toFixed(2), (JSON.parse(element.measuredDataJson).BPM).toFixed(2), (JSON.parse(element.measuredDataJson).PI).toFixed(2)]);
							oximeterDate.push([formatterFilter(element.measuredAt, 'date_med', this.timeLocalization)]);
						}
						if (JSON.parse(element.measuredDataJson).Sys !== undefined && JSON.parse(element.measuredDataJson).Dias !== undefined) {
							bpmBody.push([formatterFilter(element.measuredAt, 'datetime_med', this.timeLocalization), JSON.parse(element.measuredDataJson).Sys, JSON.parse(element.measuredDataJson).Dias, JSON.parse(element.measuredDataJson).Pul]);
							bpmDate.push([formatterFilter(element.measuredAt, 'date_med', this.timeLocalization)]);
						}
						if (JSON.parse(element.measuredDataJson).weight_lbs !== undefined && JSON.parse(element.measuredDataJson).weight_kg !== undefined) {
							scaleBody.push([formatterFilter(element.measuredAt, 'datetime_med', this.timeLocalization), JSON.parse(element.measuredDataJson).weight_lbs, JSON.parse(element.measuredDataJson).weight_kg]);
							scaleDate.push([formatterFilter(element.measuredAt, 'date_med', this.timeLocalization)]);
						}
						if (JSON.parse(element.measuredDataJson).glucose_mgL !== undefined && JSON.parse(element.measuredDataJson).glucose_mmolL !== undefined && JSON.parse(element.measuredDataJson).glucose_mgL !== 0 && JSON.parse(element.measuredDataJson).glucose_mmolL !== 0) {
							glucoseBody.push([formatterFilter(element.measuredAt, 'datetime_med', this.timeLocalization), JSON.parse(element.measuredDataJson).glucose_mgL, JSON.parse(element.measuredDataJson).glucose_mmolL]);
							glucoseDate.push([formatterFilter(element.measuredAt, 'date_med', this.timeLocalization)]);
						}
						if (JSON.parse(element.measuredDataJson).cholesterol_mgL !== undefined && JSON.parse(element.measuredDataJson).cholesterol_mmolL !== undefined) {
							cholesterolBody.push([formatterFilter(element.measuredAt, 'datetime_med', this.timeLocalization), JSON.parse(element.measuredDataJson).cholesterol_mgL, JSON.parse(element.measuredDataJson).cholesterol_mmolL]);
							cholesterolDate.push([formatterFilter(element.measuredAt, 'date_med', this.timeLocalization)]);
						}
					});
				}
			});
			var pageCount = doc.internal.getNumberOfPages();
			doc.deletePage(pageCount);
			// if (temperatureBody.length > 0 || oximeterBody.length || bpmBody.length || scaleBody.length || glucoseBody.length || cholesterolBody.length) {
			doc.save(`Patient ${this.detailedUser.userData?.firstName} ${this.detailedUser.userData?.lastName}.pdf`);
			// }
		},
	},
	async mounted () {
		const data = {
			id: this.detailedUser.userData.id,
			filter: 'LAST7',
		};
		await this.getHubsData(data);
	},
};
</script>
<style scoped>
.card-content-container {
  height: calc(100% - 90px); /* Adjust as needed */
  overflow: auto;
}
.fixed-title {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
