<template >
	<v-app style="overflow: hidden">
		<v-row :style="`background-color: black; height: ${testHeight + 5}px;`">
			<v-col class="" cols="12" xl="8" lg="7" md="12" sm="12">
				<v-card class="video-room-video" color="black" :height="testHeight">
					<v-col class="fill-height">
						<div v-if="callEnded">
							<span>Call Ended</span><br />
							<v-btn @click="reJoin">Rejoin<v-icon small>mdi-arrow-left-bottom</v-icon></v-btn>
						</div>
						<div style="height: 100%" v-if="!callEnded">
							<v-row class="fill-height">
								<v-col :cols="videoTrack ? 9 : 12" class="d-flex flex-column justify-center align-center">
									<div style="border-radius: 8px; background-color: black">
										<v-card v-if="!videoTrack" color="black" class="d-flex flex-column justify-center align-center" :height="testHeight - 150" width="600">
											<v-card-text>
												<v-avatar
													class="mt-16"
													color="secondary"
													size="240"
												>
													<span v-if="userDetails" class="font-weight-bold black--text" style="font-size: 36px;">{{ userDetails.firstName.charAt(0) }}{{ userDetails.lastName.charAt(0) }}</span>
												</v-avatar>
											</v-card-text>
										</v-card>
										<video
											v-show="videoTrack"
											autoplay
											:style="`border-radius: 8px; max-width: 100%`"
											:height="testHeight - 30"
											preload="none"
											poster="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYniamX9xxvMlgmWIrZXmiueRb_Errvrqkaw&usqp=CAU"
											id="patientvideo"
										></video>
									</div>
								</v-col>
								<v-col v-if="videoTrack" cols="3" class="d-flex">
									<div>
										<!-- <v-card v-if="!videoTrack" color="grey" height="150" width="250" class="fill-height d-flex flex-column justify-center align-center">
											<v-avatar
												color="secondary"
												size="120"
											>
												<span v-if="userDetails" class="font-weight-bold primary--text" style="font-size: 24px;">{{ loggedUser.firstName.charAt(0) }}{{ loggedUser.lastName.charAt(0) }}</span>
											</v-avatar>
										</v-card> -->
										<video
											v-show="videoTrack"
											autoplay
											style="border-radius: 8px"
											preload="none"
											poster="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYniamX9xxvMlgmWIrZXmiueRb_Errvrqkaw&usqp=CAU"
											width="100%"
											muted
											id="localvideo"
										></video>
									</div>
								</v-col>
								<v-col style="margin-top: -12%" :cols="videoTrack ? 9 : 12">
									<v-btn fab class="mx-2" @click="stopAudio">
										<v-icon v-if="!mutedAudio">mdi-microphone</v-icon>
										<v-icon v-if="mutedAudio">mdi-microphone-off</v-icon>
									</v-btn>
									<v-btn fab class="mx-2" color="red" @click="endCall"><v-icon color="white">mdi-phone-hangup</v-icon></v-btn>
									<!-- <v-btn fab class="mx-2" @click="stopVideo">
										<v-icon v-if="!mutedVideo">mdi-camera</v-icon>
										<v-icon v-if="mutedVideo">mdi-camera-off</v-icon>
									</v-btn> -->
								</v-col>
							</v-row>
						</div>
					</v-col>
				</v-card>
			</v-col>
			<v-col class="mt-2 pr-6" style="background-color: white" cols="12" xl="4" lg="5" md="12" sm="12">
				<patient-details-card-for-video
					v-if="detailedUser"
					@device-edited="deviceEdited"
					:noChat="true"
					:video="true"
					:type="'room'"
				/>
				<patient-measurements-for-video/>
			</v-col>
		</v-row>

	</v-app>
  </template>

<script>
import { Janus } from 'janus-gateway';
import { mapState } from 'vuex';
import PatientDetailsCardForVideo from '@/views/Client/components/PatientDetailsCardForVideo';
import PatientMeasurementsForVideo from '../components/PatientMeasurementsForVideo.vue';
import { seeMoreWhite } from '@/assets/';
var socketUrl = process.env.VUE_APP_JANUS_SERVER_URL;
// var socketUrl = 'wss://testjanus.vitalcheckups.com';
var vroomHandle = null;
var Vue = null;
// var videoStream = null;
export default {
	name: 'App',
	components: {
		PatientDetailsCardForVideo,
		PatientMeasurementsForVideo,
	},
	data () {
		return {
			janus: null,
			loading: false,
			notes: '',
			mutedAudio: false,
			mutedVideo: true,
			disabledVideo: false,
			disabledAudio: false,
			publishers: [],
			callEnded: false,
			snackStatus: false,
			title: '',
			myNotes: null,
			snackColor: '',
			see_more_white: seeMoreWhite,
			showNotesDialog: false,
			roomJoinId: null,
			needsToBeRecorded: null,
		};
	},
	computed: {
		...mapState({
			loggedUser: (state) => state.authentication.userData,
			detailedUser: (state) => state.users.detailedUser,
			notesFromRepo: (state) => state.appointments.notes,
		}),
		videoTrack () {
			// console.log('id video track needed: ', this.$route.params.videotrack);
			return this.$route.params.videotrack;
		},
		appointmentDetails () {
			return this.$route.params.details;
		},
		userDetails () {
			return this.detailedUser?.userData;
		},
		returnimage () {
			return 'https://icons.veryicon.com/png/o/object/material-design-icons-1/account-outline.png';
		},
		fistPublisher () {
			return this.publishers[0]?.id;
		},
		testHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return window.innerHeight - 150;
			case 'sm':
				return window.innerHeight - 60;
			case 'md':
				return window.innerHeight - 60;
			case 'lg':
				return window.innerHeight - 40;
			case 'xl':
				return window.innerHeight - 40;
			default:
				return 0;
			}
		},
		alignment () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return 'col-center-alignment';
			case 'sm':
				return 'col-center-alignment';
			case 'md':
				return 'col-center-alignment';
			case 'lg':
				return 'col-left-alignment';
			case 'xl':
				return 'col-left-alignment';
			default:
				return 'col-left-alignment';
			}
		},
	},
	async mounted () {
		localStorage.appointmentIdToStop = this.$route.params.appointmentId;
		if (localStorage.sessionToken === undefined) {
			localStorage.sessionToken = this.$route.params.token;
			await this.$store.dispatch('authentication/doLoginWithToken');
		} else {
			await this.$store.dispatch('authentication/doLoginWithToken');
		}
		if (this.$route.params.id && this.$route.params.userId) {
			await this.getUserById(this.$route.params.userId);
			this.init(parseInt(this.$route.params.id), this.$route.params.needVideoRecording);
		}
		this.getAllNotes();
	},
	async destroyed () {
		Vue = null;
		await this.addAttendeeForAppointment(localStorage.appointmentIdToStop, 'OUT');
		await this.stopAppointment(localStorage.appointmentIdToStop);
		// console.log('async destroyed');
		vroomHandle.hangup();
		this.janus.destroy();
		localStorage.removeItem('appointmentIdToStop');
	},
	methods: {
		async startAppointment (appointmentId) {
			const body = {
				appointmentId: appointmentId,
			};
			this.$store.dispatch('appointments/startAppointment', body);
		},
		stopAppointment (appointmentId) {
			const body = {
				appointmentId: appointmentId,
			};
			this.$store.dispatch('appointments/stopAppointment', body);
		},
		async addAttendeeForAppointment (appointmentId, joinType) {
			const body = {
				appointmentId: appointmentId,
				attendeeId: this.loggedUser.id,
				joinType: joinType,
				roomJoinId: this.roomJoinId,
			};
			this.$store.dispatch('appointments/addAttendeeForAppointment', body);
		},
		deviceEdited () {
			this.getUserById(this.$route.params.userId);
		},
		async getUserById (userId) {
			await this.$store.dispatch('users/getUserById', userId);
		},
		reJoin () {
			this.callEnded = false;
			this.mutedAudio = false;
			this.init(parseInt(this.$route.params.id));
		},
		// async endCall () {
		//  console.log('async endCall');
		// 	await vroomHandle.hangup();
		// 	await this.janus.destroy();
		// 	// this.$router.push({ name: 'Dashboard', params: { type: 'endCall' } });
		// 	this.$router.push({ name: 'Patient Details', params: { id: `${this.$route.params.userId}` } });
		// },
		async endCall () {
			// Stop the local video
			vroomHandle.muteVideo();

			// Stop the local audio
			vroomHandle.muteAudio();

			// Hang up the call
			vroomHandle.hangup();

			// Destroy the Janus session
			this.janus.destroy();

			// // Close the media streams
			// const localVideo = document.getElementById('localvideo');
			// const remoteVideo = document.getElementById('patientvideo');
			// if (localVideo.srcObject) {
			// 	const tracks = localVideo.srcObject.getTracks();
			// 	tracks.forEach(track => track.stop());
			// }
			// if (remoteVideo.srcObject) {
			// 	const tracks = remoteVideo.srcObject.getTracks();
			// 	tracks.forEach(track => track.stop());
			// }

			// Redirect to the desired page
			// this.$router.push({ name: 'Dashboard', params: { type: 'endCall' } });
			this.$router.push({ name: 'Patient Details', params: { id: `${this.$route.params.userId}`, type: 'endCall' } });
		},
		stopAudio () {
			this.mutedAudio = !this.mutedAudio;
			if (this.mutedAudio) {
				// console.log('stopAudio mute audio');
				vroomHandle.muteAudio();
			} else {
				// console.log('stopAudio unmute audio');
				vroomHandle.unmuteAudio();
			}
		},
		stopVideo () {
			this.mutedVideo = !this.mutedVideo;
			if (this.mutedVideo) {
				// console.log('stopAudio unmute video');
				vroomHandle.muteVideo();
			} else {
				// console.log('stopAudio unmute video');
				vroomHandle.unmuteVideo();
			}
		//   if (this.mutedVideo) {
		//     document.getElementById('localvideo').srcObject = new MediaStream();
		//     vroomHandle.muteVideo();
		//   } else {
		//     document.getElementById('localvideo').srcObject = videoStream;
		//     vroomHandle.unmuteVideo();
		//   }
		},
		calculateAge (dateString) {
			var today = new Date();
			var birthDate = new Date(dateString.split('T')[0]);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
		async init (roomId, recorded) {
			Vue = this;
			Janus.init({
				debug: 'all',
				callback: () => {
					this.janus = new Janus({
						server: socketUrl,
						success: async () => {
							await this.startAppointment(this.$route.params.appointmentId);
							this.janus.attach({
								plugin: 'janus.plugin.videoroom',
								opaqueId: 'videoroom-' + Janus.randomString(12),
								success: function (pluginHandle) {
									vroomHandle = pluginHandle;
									// console.log('vroomHandle.send if roomExsist');
									vroomHandle.send({
										message: {
											request: 'exists',
											room: roomId,
										},
										success: (res) => {
											if (res.exists) {
												const reg = Janus.randomString(12);
												const register = {
													request: 'join',
													room: roomId,
													ptype: 'publisher',
													display: reg,
												};
												// console.log('vroomHandle.send  join on existing room');
												vroomHandle.send({ message: register });
											} else {
												// console.log('vroomHandle.send  to create new room');
												vroomHandle.send({
													message: {
														request: 'create',
														room: roomId,
														description: 'Doctor',
														publishers: 2,
														record: recorded,
													},
													success: () => {
														const reg = Janus.randomString(12);
														// console.log('vroomHandle.send  join after create');
														const register = {
															request: 'join',
															room: roomId,
															ptype: 'publisher',
															display: reg,
														};
														vroomHandle.send({ message: register });
													},
												});
											}
										},
									});
								},
								onmessage: async (msg, jsep) => {
									// console.log('MESSAGE: =====', msg);
									const event = msg.videoroom;
									if (event !== undefined && event !== null) {
										if (event === 'joined') {
											this.roomJoinId = (msg.id).toString();
											await this.addAttendeeForAppointment(this.$route.params.appointmentId, 'IN');
											const list = msg.publishers;
											this.publishers = list;
											for (const f in list) {
												const id = list[f].id;
												const display = list[f].display;
												const audio = list[f].audio_codec;
												const video = list[f].video_codec;
												this.newRemoteFeed(id, display, audio, video, roomId);
											}
											this.publishOwnFeed(true);
										} else if (event === 'event') {
											if (msg.publishers !== undefined && msg.publishers !== null) {
												const list = msg.publishers;
												this.publishers = list;
												for (const f in list) {
													const id = list[f].id;
													const display = list[f].display;
													const audio = list[f].audio_codec;
													const video = list[f].video_codec;
													this.newRemoteFeed(id, display, audio, video, roomId);
												}
											} else if (msg.leaving !== undefined && msg.leaving !== null) {
												this.publishers = this.publishers.filter(function (obj) { return obj.id !== msg.leaving; });
												if (this.publishers.length === 0) {
													const video = document.getElementById('patientvideo');
													var media = new MediaStream();
													video.srcObject = media;
												}
											} else if (msg.unpublished !== undefined && msg.unpublished !== null) {
												// One of the publishers has unpublished?
												if (msg.unpublished === 'ok') {
													// console.log('on hangup from one remote');
													vroomHandle.hangup();
													return;
												}
											} else if (msg.error !== undefined && msg.error !== null) {
												if (msg.error_code === 426) {
													// This is a "no such room" error: give a more meaningful description
												} else if (msg.error_code === 432) {
													// If max number of publishers is reached
													// console.log('vroomHandle.send  hangup on max number of remotes reach for the room');
													vroomHandle.hangup();
													this.janus.destroy();
													this.callEnded = true;
													alert(msg.error);
													// This is a "no such room" error: give a more meaningful description
												} else {
													alert(msg.error);
												}
											}
										} else if (event === 'destroyed') {
											this.endCall();
											this.janus.destroy();
										}
									}
									if (jsep !== undefined && jsep !== null) {
										// console.log('vroomHandle.send  handle remote offer');
										vroomHandle.handleRemoteJsep({ jsep: jsep });
									}
								},
								onlocalstream: function (stream) {
									const video = document.getElementById('localvideo');
									if (stream !== undefined && video !== null) {
										video.srcObject = stream;
									}
								},
								onlocaltrack: function (track, added) {
									// console.log('onlocaltrack');
								},
								onremotetrack: function (track, mid, added) {
									// console.log('onremotetrack');
								},
								oncleanup: function () {
									// console.log('oncleanup');
								},
								error: function (track, mid, added) {
									// console.log('error');
								},
								consentDialog: function (track, mid, added) {
									// console.log('consentDialog');
								},
								iceState: function (track, mid, added) {
									// console.log('iceState');
								},
								mediaState: function (track, mid, added) {
									// console.log('mediaState');
								},
								webrtcState: function (track, mid, added) {
									// console.log('webrtcState');
								},
								slowLink: function (track, mid, added) {
									// console.log('slowLink');
								},
								onremotestream: function (track, mid, added) {
									// console.log('onremotestream');
								},
								ondata: function (track, mid, added) {
									// console.log('ondata');
								},
								ondataopen: function (track, mid, added) {
									// console.log('ondataopen');
								},
								ondetached: function (track, mid, added) {
									// console.log('ondetached');
								},
							});
						},
						error: () => {
							// REJOIN HERE
							Vue.snackStatus = false;
							Vue.callEnded = true;
							Vue.snackStatus = true;
							Vue.title = 'Error connecting to server, please try again!';
							Vue.snackColor = 'deep-orange darken-4';
							this.reJoin();
						},
						destroyed: function () {
							// destroyed init object
						},
					});
				},
			});
		},
		publishOwnFeed (useAudio) {
			// console.log('create local offer');
			vroomHandle.createOffer({
				media: {
					audioRecv: true,
					videoRecv: true,
					audioSend: useAudio,
					videoSend: this.$route?.params?.videotrack,
				},
				success: function (jsep) {
					// console.log('succesfuly local offer created');
					Janus.debug('Got publisher SDP!');
					Janus.debug(jsep);
					const publish = {
						request: 'publish',
						audio: useAudio,
						video: this.$route?.params?.videotrack,
					};
					// console.log('vroomHandle.send  send jsep offer to remote party');
					vroomHandle.send({ message: publish, jsep: jsep });
				},
				error: function (error) {
					Janus.error('WebRTC error ===== ==:', error);
					// if (useAudio) {
					// 	this.publishOwnFeed(false);
					// }
				},
			});
		},
		newRemoteFeed (id, display, audio, video, roomId) {
			let remoteFeed = null;
			this.janus.attach({
				plugin: 'janus.plugin.videoroom',
				opaqueId: 'videoroom-' + Janus.randomString(12),
				success: function (pluginHandle) {
					remoteFeed = pluginHandle;
					const subscribe = {
						request: 'join',
						room: roomId,
						ptype: 'subscriber',
						feed: id,
					};
					remoteFeed.videoCodec = video;
					remoteFeed.send({ message: subscribe });
				},
				onmessage: function (msg, jsep) {
					if (jsep) {
						remoteFeed.createAnswer({
							jsep: jsep,
							media: { audioSend: true, videoSend: true }, // We want recvonly audio/video
							success: function (jsep) {
								const body = { request: 'start', room: roomId };
								remoteFeed.send({ message: body, jsep: jsep });
							},
							// eslint-disable-next-line handle-callback-err
							error: function (error) {
								// console.error('WebRTC error:', error);
							},
						});
					}
				},
				onremotestream: function (stream) {
					if (stream !== undefined) {
						const video = document.getElementById('patientvideo');
						video.srcObject = stream;
					}
				},
			});
		},
		async getAllNotes () {
			this.loading = true;
			await this.$store.dispatch('appointments/getNotesWithPatient', parseInt(this.detailedUser?.userData.id));
			this.myNotes = this.notesFromRepo;
			this.loading = false;
		},
	},
};
</script>
  <style scoped>
  #container {
	width: 100px;
	height: 100px;
	position: relative;
  }
  #navi,
  #infoi {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	position: absolute;
	bottom: 0;
	left: 0;
  }
  #infoi {
	z-index: 10;
  }

  ::-webkit-scrollbar {
	width: 0px !important;
  }
  </style>
